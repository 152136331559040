import find from 'lodash/find';
import PropTypes from 'prop-types';
import React from 'react';
import {MapContainer} from 'react-leaflet';
import {GoogleLayer} from 'react-leaflet-google-v2';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import gettext from 'airborne/gettext';
import {hotelsTestIds} from 'airborne/hotels/testIds';
import hotelSelector, {getAlternativeHotel} from 'airborne/search2/helpers/hotelSelector';
import settings from 'airborne/settings';

import 'react-leaflet-markercluster/dist/styles.min.css';

import HotelTab from './HotelTab';

import {hotelMarker} from 'airborne/search2/map3/HotelMarker';

const DEFAULT_MAP_ZOOM = 15;

const mapStateToProps = createSelector(
    [hotelSelector, (_, {id}) => id, state => getAlternativeHotel(state)],
    (hotels, id, alternativeHotel) => {
        const hotel = find(hotels, {id});
        if (hotel) {
            return {
                lat: hotel.latitude,
                lng: hotel.longitude,
                name: hotel.name,
                hotel,
            };
        }
        if (Boolean(alternativeHotel)) {
            return {
                lat: alternativeHotel.latitude,
                lng: alternativeHotel.longitude,
                name: alternativeHotel.name,
                hotel: alternativeHotel,
            };
        }
        return {};
    }
);

@connect(mapStateToProps)
export default class Map3 extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        hotel: PropTypes.object.isRequired,
    };

    renderMap(latitude, longitude, name, hotel) {
        return (
            <div style={{width: '100%', height: 414}}>
                <MapContainer center={[latitude, longitude]} zoom={DEFAULT_MAP_ZOOM}>
                    <GoogleLayer googlekey={settings.GOOGLE_API_KEY} maptype="ROADMAP" />
                    {hotelMarker({latitude, longitude, name, ...hotel})}
                </MapContainer>
            </div>
        );
    }

    renderNomap() {
        return (
            <div>
                {gettext(
                    'AgentSource Booking does not have sufficient information to display exact location of this hotel.'
                )}
            </div>
        );
    }

    render() {
        const {id, lat, lng, name, hotel} = this.props;
        return (
            <HotelTab id={id} title={gettext('Map')} testId={hotelsTestIds.results.hotelsTabsContentMap}>
                {lat && lng ? this.renderMap(lat, lng, name, hotel) : this.renderNomap()}
            </HotelTab>
        );
    }
}
